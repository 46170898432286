import { Responsible, MutationAcceptInvitationArgs } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { RESPONSIBLE_FIELDS } from "@/graphql/responsible/responsible.sdl";
import { useMutation } from "@vue/apollo-composable";
import { USER_FIELDS } from "@/graphql/user/user.sdl";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { deletePaginationCache } from "@/graphql/utils/pagination";

type AcceptInvitationData = {
  acceptInvitation: Responsible;
};
const ACCEPT_INVITATION = gql`
    mutation AcceptInvitation($activityId: Int!) {
      acceptInvitation(activityId: $activityId) {
            ${RESPONSIBLE_FIELDS}
            user {
                ${USER_FIELDS}
            }
        }
    }
`;

export const useAcceptInvitation = () => {
  const toast = useToast();
  const { t } = useI18n();
  const {
    mutate,
    loading: acceptLoading,
    onDone,
  } = useMutation<AcceptInvitationData, MutationAcceptInvitationArgs>(
    ACCEPT_INVITATION,
    {
      update(cache, { data }) {
        if (data?.acceptInvitation?.accepted) {
          cache.modify({
            fields: {
              userResponsible(existingRef: any[], { toReference }) {
                return [toReference(data.acceptInvitation), ...existingRef];
              },
              invitations(existing: any, { toReference, readField }) {
                return deletePaginationCache(
                  [data.acceptInvitation.id],
                  existing,
                  readField,
                  toReference
                );
              },
            },
          });
        }
      },
    }
  );
  onDone(({ data }) => {
    toast.add({
      severity: "success",
      summary: t("responsible.create.title"),
      detail: t(
        `responsible.confirmation.${
          data?.acceptInvitation?.accepted ? "success" : "failed"
        }`
      ),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });
  function submitAcceptation(activityId: number) {
    void mutate({ activityId });
  }
  return {
    acceptLoading,
    submitAcceptation,
  };
};
