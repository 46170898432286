import {
  ResponsiblePagination,
  PaginationInput,
  QueryInvitationsArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { RESPONSIBLE_FIELDS } from "@/graphql/responsible/responsible.sdl";
import { useQuery, useResult } from "@vue/apollo-composable";
import { InitialPagination, PAGINATION_META } from "@/graphql/utils/pagination";
import { reactive } from "vue";

type InvitationsData = {
  invitations: ResponsiblePagination;
};
const INVITATIONS = gql`
    query Invitations($input: PaginationInput!) {
        invitations(input: $input) {
            items {
                ${RESPONSIBLE_FIELDS}
                activity {
                    id
                    name
                    country
                    address
                }
            }
            ${PAGINATION_META}
        }
    }
`;

export const useInvitations = () => {
  const input = reactive<PaginationInput>({
    limit: 20,
    page: 1,
  });
  const { result, loading } = useQuery<InvitationsData, QueryInvitationsArgs>(
    INVITATIONS,
    { input }
  );
  const invitations = useResult<
    InvitationsData,
    ResponsiblePagination,
    ResponsiblePagination
  >(result, InitialPagination, (res) => {
    return res.invitations;
  });
  return { invitations, loading, input };
};
