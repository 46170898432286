
import { defineComponent } from "vue";
import { useInvitations } from "@/graphql/responsible/invitations";
import { useRemoveResponsible } from "@/graphql/responsible/remove.responsible";
import { useAcceptInvitation } from "@/graphql/responsible/accept.invitation";
import { getImage } from "@/graphql/utils/utils";

export default defineComponent({
  name: "Invitations",
  setup() {
    return {
      ...useInvitations(),
      getImage,
      ...useRemoveResponsible(),
      ...useAcceptInvitation(),
    };
  },
});
